














import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class StoreCard extends Vue {
  @Prop({ default: "" }) private readonly title!: string;
  @Prop({ default: false }) private readonly headerBorder!: boolean | string;
  @Prop({ default: "8px" }) private readonly borderRadius!: string;

  get rootStyle(): Record<string, string> {
    const { borderRadius } = this;
    return {
      borderRadius,
    };
  }

  get isHeaderBorder(): boolean {
    return this.headerBorder !== false;
  }
}

















import { Component, Prop, Vue } from "vue-property-decorator";
import { OrderCheckoutProductItem } from "@/api/cap-store.api";

@Component({})
export default class OrderProductItem extends Vue {
  @Prop() private readonly item!: OrderCheckoutProductItem;
}

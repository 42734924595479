
































































import { Component, Ref, Vue } from "vue-property-decorator";
import StoreCard from "@/views/store/components/StoreCard.vue";
import CapStoreApi, {
  OrderCheckout,
  StoreBuyProductResult,
} from "@/api/cap-store.api";
import ConfirmOut from "@/components/ConfirmOut.vue";
import OrderProductItem from "@/views/store/components/OrderProductItem.vue";
import OrderProductAddressItem from "@/views/store/components/OrderProductAddressItem.vue";
import { IAddress, MineApi } from "@/api/mine.api";
import AddressForm from "@/components/AddressForm.vue";
import { Toast } from "vant";
import { getModule } from "vuex-module-decorators";
import GlobalStateStore from "@/store/modules/global-state.store";
import { systemPay, wechatPay } from "@/utils/common.util";
import PayPop from "@/views/destiny-draw/components/destinyFate/PayPop.vue";
import { VanToast } from "vant/types/toast";
import WalletPaymentSelector from "@/components/WalletPaymentSelector.vue";
@Component({
  components: {
    WalletPaymentSelector,
    AddressForm,
    OrderProductAddressItem,
    OrderProductItem,
    StoreCard,
    ConfirmOut,
    PayPop,
  },
})
export default class StoreOrder extends Vue {
  @Ref("WalletPaymentSelector")
  private readonly paymentSelectorRef!: WalletPaymentSelector;

  globalState = getModule(GlobalStateStore);
  goods_id = this.$route.params.id;
  show = false;
  styleState = true;
  popState = false;
  radio = "1";
  fakeMoney = 0;
  number = 0;
  timertime = 0;
  item: OrderCheckout | null = null;
  showAddress = false;
  address: IAddress = {
    address_id: 0,
    consignee: "",
    phone: "",
    provinces: null,
    city: null,
    area: null,
    detailed_address: "",
    zip_code: "",
  };
  combine(e: any) {
    this.styleState = e;
  }
  close() {
    this.styleState = true;
    this.show = false;
  }
  test() {
    this.styleState = true;
  }
  cheep(e: number) {
    this.fakeMoney = e;
  }
  checkRadio(radio: string) {
    this.radio = radio;
  }
  showPop(e: any) {
    this.show = false;
    this.popState = true;
  }
  async PayOne(res: any) {
    this.styleState = false;
    this.show = false;
    let deduction_wallet = res.deduction_wallet;
    let channel = res.pay_channel;
    const toast = Toast.loading({ message: "下单中...", mask: true });
    const result = await CapStoreApi.buyProduct(
      Number(this.goods_id),
      channel,
      channel === 3 ? this.globalState.openId || "" : "",
      deduction_wallet,
      this.address
    )
      .then((ret) => {
        toast.clear();
        return ret;
      })
      .catch((err) => {
        Toast.fail({ message: err.message, icon: "close", duration: 2000 });
        return undefined;
      });
    if (!result) return;
    const loading = Toast.loading({
      message: "支付中...",
      duration: 0,
      mask: true,
    });
    if (channel == 4) {
      toast.clear();
      await this.$router.replace({
        name: "MyOrderDetail",
        params: { order_no: result.order_no },
      });
      return;
    }
    this.PayHandler(result, toast, channel);
  }
  async handlePay(): Promise<void> {
    const { consignee } = this.address;
    if (!consignee) {
      Toast("请填写收货地址");
      return;
    }
    this.paymentSelectorRef.show(
      this.item?.actual_price || 0,
      (channel, deduction_wallet) => {
        this.PayOne({ pay_channel: channel, deduction_wallet });
      }
    );
    // this.show = true
    // this.styleState = true
  }
  ConfirmOutPopState = false;
  PayOrder: StoreBuyProductResult | undefined = undefined;
  PayToast: VanToast | undefined = undefined;
  pay_channel: number | undefined = undefined;
  async PayHandler(
    order: StoreBuyProductResult,
    toast: VanToast,
    pay_channel: number
  ) {
    this.PayOrder = order;
    this.PayToast = toast;
    this.pay_channel = pay_channel;
    const result = await systemPay(pay_channel, order);
    toast.clear();
    if (!result && ![1, 2].includes(pay_channel)) {
      document.documentElement.style.overflowY = "hidden";
      this.ConfirmOutPopState = true;
      Toast("支付取消");
      return;
    }
    if (!result && [1, 2].includes(pay_channel)) return;
    await this.$router.replace({
      name: "MyOrderDetail",
      params: { order_no: order.order_no },
    });
  }
  addressComplete(params: IAddress): void {
    this.address = params;
  }
  out() {
    this.ConfirmOutPopState = false;
  }
  confirm() {
    this.PayHandler(this.PayOrder!, this.PayToast!, this.pay_channel!);
    this.ConfirmOutPopState = false;
  }
  async mounted(): Promise<void> {
    const [item, address] = await Promise.all([
      CapStoreApi.getOrderCheckout(Number(this.goods_id)),
      MineApi.getDeliverAddress(),
    ]);
    this.item = item;
    this.address = address;
  }

  handleFillAddress(): void {
    this.showAddress = !this.showAddress;
  }
}



























import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { IAddress } from "@/api/mine.api";

@Component({})
export default class OrderProductAddressItem extends Vue {
  @Prop() private readonly address!: IAddress;

  get notAddress(): boolean {
    const { consignee } = this.address;
    return !consignee;
  }

  @Emit()
  handleFillAddress(): void {
    return;
  }
}
